exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-auth-forgot-password-js": () => import("./../../../src/pages/auth/forgot-password.js" /* webpackChunkName: "component---src-pages-auth-forgot-password-js" */),
  "component---src-pages-auth-index-js": () => import("./../../../src/pages/auth/index.js" /* webpackChunkName: "component---src-pages-auth-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-index-js": () => import("./../../../src/pages/portfolio/index.js" /* webpackChunkName: "component---src-pages-portfolio-index-js" */),
  "component---src-pages-portfolio-portfolio-three-js": () => import("./../../../src/pages/portfolio/portfolio-three.js" /* webpackChunkName: "component---src-pages-portfolio-portfolio-three-js" */),
  "component---src-pages-portfolio-portfolio-two-js": () => import("./../../../src/pages/portfolio/portfolio-two.js" /* webpackChunkName: "component---src-pages-portfolio-portfolio-two-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-refund-policy-js": () => import("./../../../src/pages/refund-policy.js" /* webpackChunkName: "component---src-pages-refund-policy-js" */),
  "component---src-pages-services-content-app-development-js": () => import("./../../../src/pages/services/content/app-development.js" /* webpackChunkName: "component---src-pages-services-content-app-development-js" */),
  "component---src-pages-services-content-business-strategy-js": () => import("./../../../src/pages/services/content/business-strategy.js" /* webpackChunkName: "component---src-pages-services-content-business-strategy-js" */),
  "component---src-pages-services-content-data-analysis-js": () => import("./../../../src/pages/services/content/data-analysis.js" /* webpackChunkName: "component---src-pages-services-content-data-analysis-js" */),
  "component---src-pages-services-content-digital-marketing-js": () => import("./../../../src/pages/services/content/digital-marketing.js" /* webpackChunkName: "component---src-pages-services-content-digital-marketing-js" */),
  "component---src-pages-services-content-graphics-design-js": () => import("./../../../src/pages/services/content/graphics-design.js" /* webpackChunkName: "component---src-pages-services-content-graphics-design-js" */),
  "component---src-pages-services-content-ui-ux-design-js": () => import("./../../../src/pages/services/content/ui-ux-design.js" /* webpackChunkName: "component---src-pages-services-content-ui-ux-design-js" */),
  "component---src-pages-services-content-web-branding-js": () => import("./../../../src/pages/services/content/web-branding.js" /* webpackChunkName: "component---src-pages-services-content-web-branding-js" */),
  "component---src-pages-services-content-web-development-js": () => import("./../../../src/pages/services/content/web-development.js" /* webpackChunkName: "component---src-pages-services-content-web-development-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-service-four-js": () => import("./../../../src/pages/services/service-four.js" /* webpackChunkName: "component---src-pages-services-service-four-js" */),
  "component---src-pages-services-service-three-js": () => import("./../../../src/pages/services/service-three.js" /* webpackChunkName: "component---src-pages-services-service-three-js" */),
  "component---src-pages-services-service-two-js": () => import("./../../../src/pages/services/service-two.js" /* webpackChunkName: "component---src-pages-services-service-two-js" */),
  "component---src-pages-terms-condition-js": () => import("./../../../src/pages/terms-condition.js" /* webpackChunkName: "component---src-pages-terms-condition-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */)
}

